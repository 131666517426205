// @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap');
// @import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css');
@import "./assets/animate.min.css";
@import "./assets/fonts.css";


@mixin magic-border($width, $color, $duration, $direction){
    position:relative;
      &:before{
        content:'';
        position:absolute;
        width:calc(100% + #{$width * 2});
        height:calc(100% + #{$width * 2});
        top:calc(#{$width}/-1);
        left:calc(#{$width}/-1);
        background:linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to left, $color 0%, $color 100%);
        background-size:100% $width, $width 200%, $width 200%, 0% $width, 0% $width;
        background-position:50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
        background-repeat:no-repeat, no-repeat;
        transition:transform $duration ease-in-out, background-position $duration ease-in-out, background-size $duration ease-in-out;
        transform:scaleX(0) rotate(180deg * $direction);
        transition-delay:$duration*2, $duration, 0s;
      }
      &:hover{
        &:before{
          background-size:200% $width, $width 400%, $width 400%, 55% $width, 55% $width;
          background-position:50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
          transform:scaleX(1) rotate(180deg * $direction);
          transition-delay:0s, $duration, $duration*2;
        }
      }
  }
  
  @mixin magic-border-mask($width, $duration, $background, $direction){
    position:relative;
      &:before{
        content:'';
        position:absolute;
        width:calc(100% + #{$width * 2});
        height:calc(100% + #{$width * 2});
        top:calc(#{$width}/-1);
        left:calc(#{$width}/-1);
        mask:linear-gradient(to right, #000 0%, #000 100%), linear-gradient(to top, #000 50%, transparent 50%), linear-gradient(to top, #000 50%, transparent 50%), linear-gradient(to right, #000 0%, #000 100%), linear-gradient(to left, #000 0%, #000 100%);
        mask-size:100% $width, $width 200%, $width 200%, 0% $width, 0% $width;
        mask-position:50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
        mask-repeat:no-repeat, no-repeat;
        transition:transform $duration ease-in-out, mask-position $duration ease-in-out, mask-size $duration ease-in-out;
        transform:scaleX(0) rotate(180deg * $direction);
        transition-delay:$duration*2, $duration, 0s;
        background:$background;
      }
      &:hover{
        &:before{
          mask-size:200% $width, $width 400%, $width 400%, 55% $width, 55% $width;
          mask-position:50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
          transform:scaleX(1) rotate(180deg * $direction);
          transition-delay:0s, $duration, $duration*2;
        }
      }
  }


body{
    font-family: 'Source Sans Pro', sans-serif;
}

.navlink{
  text-decoration: none;
  font-family: inherit;
  color: inherit;
}

.navpad{
  height: 5vw;
}

.navbar{
    color: white;
    background-color: black;
    position:fixed;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10%;
    .navbarElement{
        display: inline;
        align-items: center;
        padding: 0.7em;
        margin-left: 1em;
        cursor: pointer;
        width: 5em;
        text-align: center;
        margin-bottom: 1em;
        transition: 0.5s ease;
        @include magic-border(1px, rgba(255, 255, 255, 0.4), 0.3s, 0)
    }

    .navlogo{
      margin-top: 5px;
      // width: 10vw;
      left: 0;
      position: absolute;
      img{
        width: 30%;
        margin-left: 0;
      }
    }

}

.wholeCarousel{
  width: 80vw;
  margin-left: 10vw;
  .carouselTile{
    width: 80vw;
    margin-left: 0vw;
    height: 80vh;
    margin-top: 1.6em;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1.5;
    .carouselTileText{

    }
    
  }
}

.about{
  width: 100vw;
  padding-bottom: 5em;
  height: auto;
  clear: both;
  .aboutImageSection{
    // background-color: #ff6532;
    height: auto;
    margin-left: 5vw;
    float: left;
    .aboutImageSign{
      margin-left: 2vw;
    }
    img{
      width: auto;
      height: auto;
      margin-left: 2vw;
      border-radius: 5%;
    }
  }
  .aboutTextSection{
    margin-right: auto;
    line-height: 2em;
    margin-left: auto;
    width: 70vw;
  }
  
}

.therapies{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .therapie{
    .displayHeaderWrapper{
      padding-top: 0;
      background-color: rgba(0, 0, 0,0.5);
      margin-top: 6em;
      width: 100%;
      color: white;
      .displayHeader{
        
          font-size: 1em;
  
      }

      // z-index: 99;
      // background: black;
      // border-bottom-right-radius: 5%;
      // border-bottom-left-radius: 5%;
      position: absolute;
      .contactIcon{
        color: #75c7ce;
        font-size: 1.6em;
        margin-top: 0.4em;
      }
    }
    
    width: 200px;
    height: 300px;
    margin-left: 2vw;
    margin-top: 2vh;
    display: flex;
    justify-content: center;
    color:white;
    text-align: center;
    align-items: center;
    display: inline-block;
    border-radius: 5%;
    background-size: cover; //Here is that shit 
    background-position: center;
    background-repeat: no-repeat;
    // opacity: 0.9;
    // filter: saturate(70%);
    // background-color: rgba(0, 0, 0,0.5);
    transition: 0.5s ease;
    position: relative;
    cursor: pointer;
  }
  .therapie:hover .therapieOverlay{
    height: 100%;
  }
  .therapieOverlay{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0,0.5);
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
    border-radius: 5%;
  }
  .therapieText{
    color: white;
    font-size: 20px;
    .contactIcon{
      color: #75c7ce;
    }
    position: absolute;
    font-size: 1.1em;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    user-select: none;
    button{
      color: unset;
      background-color: unset;
      background-image: unset;
    }
    
  }

  
}

.bulletPoints{
  .contactIcon{
    color: #75c7ce;
    padding-right: 0.8em;
  }
  
}

.therapyActiveCard{
  width: 80vw;
  line-height: 2em;
  margin-left: 10vw;
  white-space: pre-wrap;
  h2{
    text-align: center;
  }
}

.footer{
  margin-top: 20vh;
  display: flex;
  background-color: #000;
  color: white;
  .footerAddress{
    width: 50vw;
    // background-color: aqua;
    .footerInner{
      margin-left: 2vw;
      span{
        text-decoration: none;
        color: white;
        // margin-left: 3em;
      }
      #footerDatenschutz{
        margin-left: 1em;
      }
    }
  }
  .footerIcon{
    margin-right: 2vw;
    color: rgba(255, 255, 255, 0.4);
  }

  .footerRest{
    width: 100vw;
  }
}

.pageNotFound{
  h1{
    font-weight: bold;
  }
  p{
    font-weight: bold;
  }
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 15vw;
}

.therapieen{
  // padding-bottom: 10em;
  padding-top: 5vw;
  h2{
    text-align: center;
  }
  .therapieen-front-line{
    margin-left: 20vw;
    margin-right: 20vw;
    text-align: left;
  }
}

.impressum{
  width: 90vw;
  margin-left: 5vw;
  padding-top: 10vh;
}
.datenschutz{
  width: 90vw;
  margin-left: 5vw;
  padding-top: 10vh;
}

.contact{
  // margin-top: 20vh;
  // padding-top: 20vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  span{
    padding: 3em;
    text-align: center;
  }
  .contactIcon{
    color: #75c7ce;
    font-size: 1.8em;
  }
  h4{
    color: #75c7ce;
  }
}

.praxisView{
  // display: flex;
  padding-top: 10vh;
  width: 100vw;
  .praxisViewHeader{
    // width: 100vw;
    h2{

      margin-left: 2vw;
  
    }
    p{
      margin-left: 2vw;
    }
  }
  .praxisViewFigures{
    width: 100vw;
    // .figure{
    //   display: flex;
    // }
    justify-content: center;
    img{
      width: 384px;
      height: 512px;
      margin-left: 2vw;
      margin-bottom: 2vh;
    }
    .praxisViewFiguresRow{
      text-align: center;
    }
  }
  
}

.contactLogo{
  width: 100%;
  display: flex;
  align-items: center;
  img{
    width: 40%;
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
  }

}

@media screen and (max-width: 1000px){
  .contactLogo{
    img{
      width: 60%;
    }
  }
  .navlogo{
    display: none;

  }
  .wholeCarousel{
    width: 100vw;
    margin-left: 0vw;
  }
}

.about{
  .aboutImageSection{
    img{
      height: 50vh;
    }
  }
}


@media screen and (max-width: 800px){
  .contactLogo{
    img{
      width: 70%;
    }
  }
  .wholeCarousel{
    .carouselTile{
      height: 60vh;
      .carouselTileText{
        width: 60% !important;
        
      }
      .carouselTileTextLast{
        margin-left: 35vw !important;
      }
    }
  }
}

.about{
  .aboutImageSection{
    margin-left: 1vw;
    padding-right: 1vw;
    .aboutImageSign{
      margin-left: 0vw;
    }
    img{
      margin-left: 0vw;
      height: 40vh;
    }
  }
}

@media screen and (max-width: 600px) {
  .contactLogo{
    img{
      width: 100%;
    }
  }
  .therapieen{
    .therapieen-front-line{
      text-align: center;
    }
  }

  .navbar{
    .navbarElement{
      padding-left: 0.4em;
      padding-right: 0.4em;
      margin-left: 0.5em;
      // line-height: 0.2em;
      ;
    }
  }

  .wholeCarousel{
    width: 100vw;
    // height: 60vh;
    // height: 520vh;
    margin-left: 0vw;
    .carouselTile{
      // width: 100vw;
      height: 30vh;
      font-size: 0.7em;
      line-height: 1em;
      .carouselTileText{
        width: 60% !important;
      }
      // margin-top: 3em;
      // background-size: contain;
      // background-repeat: no-repeat;
      
    }
  }

  .praxisViewFigures{
    .praxisViewFiguresRow{
      img{
  
        margin-left: 0vw;
        margin-bottom: 0vh;
      }
  
    }
  }
  

  .about{
    width: 100vw;
    height: auto;
    margin-left: 0em;
    padding: 0%;
    // clear: both;
    .aboutImageSection{
      display: none;
      width: 0em;
      padding: 0em;

      img{
        display: none
      }
    }
    .aboutTextSection{
      margin-right: 5vw;
      line-height: 1.8em;
      margin-left: 4vw;
    }
    
  }

  .footer{
    margin-top: 20vh;
    display: block;
    background-color: #000;
    color: white;
    .footerAddress{
      width: 100vw;
      padding-top: 1vh;
      // background-color: aqua;
      .footerInner{
        margin-left: 2vw;
        
      }
    }
    .footerOpenings{
      .footerInner{
        margin-left: 2vw;
      }
    }
    .footerIcon{
      margin-right: 2vw;
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .navpad{
    height: 16vw;
  }
  .therapyActiveCard{
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    line-height: 1.5em;

  }
  .therapieen{
    padding-top: 10vh;
    .therapieen-front-line{
      margin-left: 7vw;
      margin-right: 7vw;
    }
  }
  .therapies{
    .therapie{
      width: 150px;
      h2{
        display: none;
      }
      .displayHeaderWrapper{
        display: none;
      }
      .displayHeader{
        display: none;
      }
      height: 225px;
      font-size: 0.7em;
      .therapieOverlay{
        height: 100%;
        .therapieText{
          font-size: 1.2em;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .navbar{
    .navbarElement{
      margin-left: 0.1em;
      margin-right: 0.1em;
      // padding: 0.5em;
      font-size: 0.9em;
    }
  }
}


@import "./assets/animate.min.css";
@import "./assets/fonts.css";
body {
  font-family: "Source Sans Pro", sans-serif;
}

.navlink {
  text-decoration: none;
  font-family: inherit;
  color: inherit;
}

.navpad {
  height: 5vw;
}

.navbar {
  color: white;
  background-color: black;
  position: fixed;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10%;
}
.navbar .navbarElement {
  display: inline;
  align-items: center;
  padding: 0.7em;
  margin-left: 1em;
  cursor: pointer;
  width: 5em;
  text-align: center;
  margin-bottom: 1em;
  transition: 0.5s ease;
  position: relative;
}
.navbar .navbarElement:before {
  content: "";
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: calc(1px/-1);
  left: calc(1px/-1);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), linear-gradient(to top, rgba(255, 255, 255, 0.4) 50%, transparent 50%), linear-gradient(to top, rgba(255, 255, 255, 0.4) 50%, transparent 50%), linear-gradient(to right, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), linear-gradient(to left, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%);
  background-size: 100% 1px, 1px 200%, 1px 200%, 0% 1px, 0% 1px;
  background-position: 50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
  background-repeat: no-repeat, no-repeat;
  transition: transform 0.3s ease-in-out, background-position 0.3s ease-in-out, background-size 0.3s ease-in-out;
  transform: scaleX(0) rotate(0deg);
  transition-delay: 0.6s, 0.3s, 0s;
}
.navbar .navbarElement:hover:before {
  background-size: 200% 1px, 1px 400%, 1px 400%, 55% 1px, 55% 1px;
  background-position: 50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
  transform: scaleX(1) rotate(0deg);
  transition-delay: 0s, 0.3s, 0.6s;
}
.navbar .navlogo {
  margin-top: 5px;
  left: 0;
  position: absolute;
}
.navbar .navlogo img {
  width: 30%;
  margin-left: 0;
}

.wholeCarousel {
  width: 80vw;
  margin-left: 10vw;
}
.wholeCarousel .carouselTile {
  width: 80vw;
  margin-left: 0vw;
  height: 80vh;
  margin-top: 1.6em;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1.5;
}
.about {
  width: 100vw;
  padding-bottom: 5em;
  height: auto;
  clear: both;
}
.about .aboutImageSection {
  height: auto;
  margin-left: 5vw;
  float: left;
}
.about .aboutImageSection .aboutImageSign {
  margin-left: 2vw;
}
.about .aboutImageSection img {
  width: auto;
  height: auto;
  margin-left: 2vw;
  border-radius: 5%;
}
.about .aboutTextSection {
  margin-right: auto;
  line-height: 2em;
  margin-left: auto;
  width: 70vw;
}

.therapies {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.therapies .therapie {
  width: 200px;
  height: 300px;
  margin-left: 2vw;
  margin-top: 2vh;
  display: flex;
  justify-content: center;
  color: white;
  text-align: center;
  align-items: center;
  display: inline-block;
  border-radius: 5%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.5s ease;
  position: relative;
  cursor: pointer;
}
.therapies .therapie .displayHeaderWrapper {
  padding-top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 6em;
  width: 100%;
  color: white;
  position: absolute;
}
.therapies .therapie .displayHeaderWrapper .displayHeader {
  font-size: 1em;
}
.therapies .therapie .displayHeaderWrapper .contactIcon {
  color: #75c7ce;
  font-size: 1.6em;
  margin-top: 0.4em;
}
.therapies .therapie:hover .therapieOverlay {
  height: 100%;
}
.therapies .therapieOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
  border-radius: 5%;
}
.therapies .therapieText {
  color: white;
  font-size: 20px;
  position: absolute;
  font-size: 1.1em;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  user-select: none;
}
.therapies .therapieText .contactIcon {
  color: #75c7ce;
}
.therapies .therapieText button {
  color: unset;
  background-color: unset;
  background-image: unset;
}

.bulletPoints .contactIcon {
  color: #75c7ce;
  padding-right: 0.8em;
}

.therapyActiveCard {
  width: 80vw;
  line-height: 2em;
  margin-left: 10vw;
  white-space: pre-wrap;
}
.therapyActiveCard h2 {
  text-align: center;
}

.footer {
  margin-top: 20vh;
  display: flex;
  background-color: #000;
  color: white;
}
.footer .footerAddress {
  width: 50vw;
}
.footer .footerAddress .footerInner {
  margin-left: 2vw;
}
.footer .footerAddress .footerInner span {
  text-decoration: none;
  color: white;
}
.footer .footerAddress .footerInner #footerDatenschutz {
  margin-left: 1em;
}
.footer .footerIcon {
  margin-right: 2vw;
  color: rgba(255, 255, 255, 0.4);
}
.footer .footerRest {
  width: 100vw;
}

.pageNotFound {
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 15vw;
}
.pageNotFound h1 {
  font-weight: bold;
}
.pageNotFound p {
  font-weight: bold;
}

.therapieen {
  padding-top: 5vw;
}
.therapieen h2 {
  text-align: center;
}
.therapieen .therapieen-front-line {
  margin-left: 20vw;
  margin-right: 20vw;
  text-align: left;
}

.impressum {
  width: 90vw;
  margin-left: 5vw;
  padding-top: 10vh;
}

.datenschutz {
  width: 90vw;
  margin-left: 5vw;
  padding-top: 10vh;
}

.contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.contact span {
  padding: 3em;
  text-align: center;
}
.contact .contactIcon {
  color: #75c7ce;
  font-size: 1.8em;
}
.contact h4 {
  color: #75c7ce;
}

.praxisView {
  padding-top: 10vh;
  width: 100vw;
}
.praxisView .praxisViewHeader h2 {
  margin-left: 2vw;
}
.praxisView .praxisViewHeader p {
  margin-left: 2vw;
}
.praxisView .praxisViewFigures {
  width: 100vw;
  justify-content: center;
}
.praxisView .praxisViewFigures img {
  width: 384px;
  height: 512px;
  margin-left: 2vw;
  margin-bottom: 2vh;
}
.praxisView .praxisViewFigures .praxisViewFiguresRow {
  text-align: center;
}

.contactLogo {
  width: 100%;
  display: flex;
  align-items: center;
}
.contactLogo img {
  width: 40%;
  margin-top: 2em;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1000px) {
  .contactLogo img {
    width: 60%;
  }

  .navlogo {
    display: none;
  }

  .wholeCarousel {
    width: 100vw;
    margin-left: 0vw;
  }
}
.about .aboutImageSection img {
  height: 50vh;
}

@media screen and (max-width: 800px) {
  .contactLogo img {
    width: 70%;
  }

  .wholeCarousel .carouselTile {
    height: 60vh;
  }
  .wholeCarousel .carouselTile .carouselTileText {
    width: 60% !important;
  }
  .wholeCarousel .carouselTile .carouselTileTextLast {
    margin-left: 35vw !important;
  }
}
.about .aboutImageSection {
  margin-left: 1vw;
  padding-right: 1vw;
}
.about .aboutImageSection .aboutImageSign {
  margin-left: 0vw;
}
.about .aboutImageSection img {
  margin-left: 0vw;
  height: 40vh;
}

@media screen and (max-width: 600px) {
  .contactLogo img {
    width: 100%;
  }

  .therapieen .therapieen-front-line {
    text-align: center;
  }

  .navbar .navbarElement {
    padding-left: 0.4em;
    padding-right: 0.4em;
    margin-left: 0.5em;
  }

  .wholeCarousel {
    width: 100vw;
    margin-left: 0vw;
  }
  .wholeCarousel .carouselTile {
    height: 30vh;
    font-size: 0.7em;
    line-height: 1em;
  }
  .wholeCarousel .carouselTile .carouselTileText {
    width: 60% !important;
  }

  .praxisViewFigures .praxisViewFiguresRow img {
    margin-left: 0vw;
    margin-bottom: 0vh;
  }

  .about {
    width: 100vw;
    height: auto;
    margin-left: 0em;
    padding: 0%;
  }
  .about .aboutImageSection {
    display: none;
    width: 0em;
    padding: 0em;
  }
  .about .aboutImageSection img {
    display: none;
  }
  .about .aboutTextSection {
    margin-right: 5vw;
    line-height: 1.8em;
    margin-left: 4vw;
  }

  .footer {
    margin-top: 20vh;
    display: block;
    background-color: #000;
    color: white;
  }
  .footer .footerAddress {
    width: 100vw;
    padding-top: 1vh;
  }
  .footer .footerAddress .footerInner {
    margin-left: 2vw;
  }
  .footer .footerOpenings .footerInner {
    margin-left: 2vw;
  }
  .footer .footerIcon {
    margin-right: 2vw;
    color: rgba(255, 255, 255, 0.4);
  }

  .navpad {
    height: 16vw;
  }

  .therapyActiveCard {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    line-height: 1.5em;
  }

  .therapieen {
    padding-top: 10vh;
  }
  .therapieen .therapieen-front-line {
    margin-left: 7vw;
    margin-right: 7vw;
  }

  .therapies .therapie {
    width: 150px;
    height: 225px;
    font-size: 0.7em;
  }
  .therapies .therapie h2 {
    display: none;
  }
  .therapies .therapie .displayHeaderWrapper {
    display: none;
  }
  .therapies .therapie .displayHeader {
    display: none;
  }
  .therapies .therapie .therapieOverlay {
    height: 100%;
  }
  .therapies .therapie .therapieOverlay .therapieText {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 450px) {
  .navbar .navbarElement {
    margin-left: 0.1em;
    margin-right: 0.1em;
    font-size: 0.9em;
  }
}

